import axios from 'axios';

const axiosConfig = {
  method: 'post',
  maxBodyLength: Infinity,
  url: process.env.VUE_APP_SHOPIFY_GRAPH_QL_URL,
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.VUE_APP_SHOPIFY_TOKEN,
    'Content-Type': 'application/json',
  },
};

export default {
  LOAD_CATEGORIES(context) {
    return loadCategories(context);
  },

  LOAD_PRODUCTS(context, data) {
    return loadProducts(context, data);
  },

  GET_SIMILAR_PRODUCTS(context, title) {
    return getSimilarProducts(context, title);
  },

  GET_LEBRON_EXQUISITE_DATABASE(context, data) {
    return getLeBronExquisiteDatabase(context, data);
  },

  LOAD_PRODUCT(context, handle) {
    return loadProduct(context, handle);
  },

  LOAD_FEATURED(context, first) {
    return loadFeatured(context, first);
  },
};

const loadCategories = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const request = JSON.stringify({
      query: `query {
        collections(first: 20) {
          edges {
            node {
              title
              handle
              image {
                url(transform:{maxHeight: 800, maxWidth: 800})
              }
            }
          }
        }
      }`,
      variables: { },
    });

    axiosConfig.data = request;

    axios.request(axiosConfig)
      .then(({ data }) => {
        const categories = data.data.collections.edges.map((collection) => {
          return collection.node;
        });

        commit('SET_CATEGORIES', categories);
        resolve();
      })
      .catch(reject);
  });
};

const loadProducts = ({ state, commit }, searchParams) => {
  return new Promise((resolve, reject) => {
    const isSameSearch = JSON.stringify(searchParams) === JSON.stringify(state.lastSearch);

    if (isSameSearch && state.products.length) {
      resolve(state.products.length);
      return;
    }

    commit('SAVE_LAST_SEARCH', searchParams);

    const {
      search = '',
      reverse,
      limit,
      sort,
      tags,
      availability,
      handle,
      endCursor = null,
    } = searchParams;

    let sortKey = searchParams.sort;

    if (!sort) {
      sortKey = 'CREATED_AT';
    }

    const queryTags = tags.reduce((final, tag) => {
      const tagString = `tag:'${tag}'`;

      return final === '' ? tagString : `${final} ${tagString}`;
    }, '');

    let productQuery = `${search} ${queryTags}`;

    if (handle) {
      handle.split('-').forEach((handlePart, i) => {
        if (i > 0) {
          productQuery += ' OR';
        }

        productQuery += ` tag:${handlePart}`;
      });
    }

    productQuery += ' product_type:trade OR product_type:sale';

    if (availability !== 'sold') {
      productQuery += ' available_for_sale:true'
    }

    const queryConfig = endCursor
      ? 'query($limit: Int!, $sortKey: ProductSortKeys, $reverse: Boolean!, $productQuery: String!, $handle: String!, $endCursor: String!) {'
      : 'query($limit: Int!, $sortKey: ProductSortKeys, $reverse: Boolean!, $productQuery: String!, $handle: String!) {';

    const productsConfig = endCursor
      ? 'products(first: $limit, sortKey: $sortKey, reverse: $reverse, query: $productQuery, after: $endCursor) {'
      : 'products(first: $limit, sortKey: $sortKey, reverse: $reverse, query: $productQuery) {';

    const request = JSON.stringify({
      query: `${queryConfig}
        ${productsConfig}
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              title
              productType
              handle
              tags
              variants(first: 1) {
                edges {
                  node {
                    id
                    price {
                      amount
                    }
                    availableForSale
                  }
                }
              }
              images(first: 10) {
                edges {
                  node {
                    url(transform:{maxHeight: 800, maxWidth: 800})
                  }
                }
              }
            }
          }
        }
        collectionByHandle(handle: $handle) {
          title
        }
      }`,
      variables: {
        limit,
        sortKey,
        reverse: !!reverse,
        productQuery,
        handle: handle || 'empty',
        endCursor,
      },
    });

    axiosConfig.data = request;

    axios.request(axiosConfig)
      .then(({ data }) => {
        const products = data.data.products.edges.map(({ cursor, node }) => {
          const product = {
            ...node,
            cursor,
            images: node.images.edges.map(({ node: imageNode }) => {
              return { url: imageNode.url };
            }),
            variants: node.variants.edges.map(({ node: variantNode }) => {
              return {
                ...variantNode,
                price: Number(variantNode.price.amount),
              };
            }),
          };

          return product;
        });

        const { pageInfo } = data.data.products;
        const { collectionByHandle } = data.data;

        if (endCursor) {
          commit('ADD_MORE_PRODUCTS', { products, pageInfo });
        } else {
          commit('SET_PRODUCTS', { products, collectionByHandle, pageInfo });
        }

        resolve(products.length);
      })
      .catch(reject);
  });
};

const getSimilarProducts = (_, { id, tags }) => {
  return new Promise((resolve, reject) => {
    let similarTags = tags.reduce((final, tag) => {
      if (tag.value === 'featured') {
        return final;
      }

      const tagString = `tag:'${tag}'`;

      return final === '' ? tagString : `${final} ${tagString}`;
    }, '');

    similarTags += ' available_for_sale:true';

    const request = JSON.stringify({
      query: `query($similarTags: String!) {
        products(first: 4, query: $similarTags) {
          edges {
            node {
              id
              title
              handle
              variants(first: 1) {
                edges {
                  node {
                    id
                    price {
                      amount
                    }
                    availableForSale
                  }
                }
              }
              images(first: 1) {
                edges {
                  node {
                    url(transform:{maxHeight: 800, maxWidth: 800})
                  }
                }
              }
            }
          }
        }
      }`,
      variables: { similarTags },
    });

    axiosConfig.data = request;

    axios.request(axiosConfig)
      .then(({ data }) => {
        const products = data.data.products.edges
          .map(({ node }) => {
            return {
              ...node,
              images: node.images.edges.map(({ node: imageNode }) => {
                return { url: imageNode.url };
              }),
              variants: node.variants.edges.map(({ node: variantNode }) => {
                return {
                  ...variantNode,
                  price: Number(variantNode.price.amount),
                };
              }),
            };
          })
          .filter((product) => product.id !== id);

        resolve(products);
      })
      .catch(reject);
  });
};

const lebronDatabase = { lebronexquisiteparalleldatabase: [], lebronexquisitedatabase: [] };

const getLeBronExquisiteDatabase = (_, { tag }) => {
  return new Promise((resolve, reject) => {
    if (lebronDatabase[tag].length) {
      resolve(lebronDatabase[tag]);

      return;
    }

    const request = JSON.stringify({
      query: `query {
        products(first: 100, query: "tag:${tag}") {
          edges {
            node {
              id
              title
              handle
              productType
              images(first: 1) {
                edges {
                  node {
                    url(transform:{maxHeight: 800, maxWidth: 800})
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {},
    });

    axiosConfig.data = request;

    axios.request(axiosConfig)
      .then(({ data }) => {
        const output = data.data.products.edges.map(({ node }) => {
          const product = {
            ...node,
            productType: 'unavailable',
            images: node.images.edges.map(({ node: imageNode }) => {
              return { url: imageNode.url };
            }),
          };

          return product;
        });

        lebronDatabase[tag] = output;
        resolve(lebronDatabase[tag]);
      })
      .catch(reject);
  });
};

const loadProduct = ({ state, commit }, handle) => {
  return new Promise((resolve, reject) => {
    if (state.productCache[handle]) {
      commit('SET_PRODUCT', state.productCache[handle]);
      resolve();
    } else {
      const request = JSON.stringify({
        query: `query($handle: String!) {
        productByHandle(handle: $handle) {
          title
          productType
          descriptionHtml
          createdAt
          tags
          variants(first: 1) {
            edges {
              node {
                id
                price {
                  amount
                }
                availableForSale
                sku
              }
            }
          }
          images(first: 10) {
            edges {
              node {
                url(transform:{maxHeight: 800, maxWidth: 800})
              }
            }
          }
        }
      }`,
        variables: { handle },
      });

      axiosConfig.data = request;

      axios.request(axiosConfig)
        .then(({ data }) => {
          const product = {
            ...data.data.productByHandle,
            images: data.data.productByHandle.images.edges.map(({ node: imageNode }) => {
              return { url: imageNode.url };
            }),
            variants: data.data.productByHandle.variants.edges.map(({ node: variantNode }) => {
              return {
                ...variantNode,
                price: Number(variantNode.price.amount),
              };
            }),
          };

          commit('SET_PRODUCT', product);
          resolve();
        })
        .catch(reject);
    }
  });
};

const loadFeatured = ({ commit }, first) => {
  return new Promise((resolve, reject) => {
    const request = JSON.stringify({
      query: `query($first: Int!) {
        products(first: $first, query: "tag:'featured'") {
          edges {
            node {
              id
              title
              handle
              images(first: 1) {
                edges {
                  node {
                    url(transform:{maxHeight: 800, maxWidth: 800})
                  }
                }
              }
            }
          }
        }
      }`,
      variables: { first },
    });

    axiosConfig.data = request;

    axios.request(axiosConfig)
      .then(({ data }) => {
        const output = data.data.products.edges.map(({ node }) => {
          const product = {
            ...node,
            images: node.images.edges.map(({ node: imageNode }) => {
              return { url: imageNode.url };
            }),
          };

          return product;
        });

        commit('SET_FEATURED', output);
        resolve();
      })
      .catch(reject);
  });
};
