import Vue from 'vue';
import ToastGroupComponent from './ToastGroup.vue';
import ToastComponent from './Toast.vue';

const ToastGroupConstructor = Vue.extend(ToastGroupComponent);
const ToastConstructor = Vue.extend(ToastComponent);

let groupInstance;
let instance;
const instances = [];
let seed = 1;

const Toast = (options) => {
  if (!groupInstance) {
    groupInstance = new ToastGroupConstructor();

    groupInstance.vm = groupInstance.$mount();
    document.body.appendChild(groupInstance.vm.$el);
    groupInstance.dom = groupInstance.vm.$el;
  }

  const id = `toast_${seed += 1}`;

  options.triggerClose = () => {
    Toast.close(id);
  };

  instance = new ToastConstructor({
    data: options,
  });

  instance.id = id;

  instance.vm = instance.$mount();
  groupInstance.vm.$el.appendChild(instance.vm.$el);

  instance.vm.message = options.message;
  instance.vm.error = options.error;

  instance.vm.active = true;
  instance.dom = instance.vm.$el;

  instances.push(instance);

  return instance.vm;
};

Toast.close = (id) => {
  for (let i = 0; i < instances.length; i += 1) {
    if (id === instances[i].id) {
      instances.splice(i, 1);
      break;
    }
  }
};

export default Toast;
