<template>
  <div class="select-field">
    <select v-model="localValue">
      <slot />
    </select>

    <icon class="chevron" name="chevron-down" />
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },

  data() {
    return {
      localValue: this.value,
    };
  },

  watch: {
    value(val) {
      this.localValue = val;
    },

    localValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-field {
  position: relative;
  background-color: $color-gray-background;
  border: 1px solid $color-gray-lighter;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-radius: $border-radius;

  &:hover {
    border-color: $color-gray-light;
  }

  select {
    cursor: pointer;
    appearance: none;
    border: 0;
    background: transparent;
    padding: $gp $gp * 2 $gp $gp;
    margin: 0;
    width: 100%;

    @media($small) {
      padding: $gp * .75 $gp * 2 $gp * .75 $gp * .75;
    }
  }
}

.chevron {
  --icon-size: 16px;

  position: absolute;
  top: calc(50% - 8px);
  right: $gp * .5;
}
</style>
