export default {
  SET_SORT_KEY(state, sortKey) {
    state.sortKey = sortKey;
  },

  SET_FILTERS_OPEN(state, open) {
    state.filtersOpen = open;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },

  SET_AVAILABILITY_FILTER(state, availability) {
    state.filters.availability = availability;
  },

  SET_GRADING_FILTER(state, grading) {
    state.filters.grading = grading;
  },

  SET_TAGS_FILTER(state, tags) {
    state.filters.tags = tags;
  },

  RESET_FILTERS(state) {
    state.filters = {
      availability: 'available',
      grading: 'all',
      tags: [],
    };
  },
};
