<template>
  <main>
    <nav-header />
    <nav-menu />
    <global-search />

    <div class="content">
      <div class="banner">Guaranteed Authentic Cards or 100% Full Refund</div>
      <router-view />
    </div>

    <nav-footer />
  </main>
</template>

<script>
import NavHeader from '@/components/Nav/NavHeader.vue';
import NavFooter from '@/components/Nav/NavFooter.vue';
import NavMenu from '@/components/Nav/NavMenu.vue';
import GlobalSearch from '@/components/Nav/GlobalSearch.vue';

export default {
  metaInfo() {
    return {
      title: 'Buying, selling and trading high end sports cards from people who know high end',
      titleTemplate: '%s | Buy Nice Cards',
      meta: [
        {
          property: 'og:url',
          content: window.location.href,
        },
        {
          property: 'og:title',
          content: 'Buy Nice Cards',
        },
        {
          property: 'og:description',
          content: 'Buying, selling and trading high end sports cards from people who know high end',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${this.baseUrl}img/logo.png`,
        },
      ],
    };
  },

  components: {
    NavHeader,
    NavFooter,
    NavMenu,
    GlobalSearch,
  },

  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      this.$store.dispatch('shopify/LOAD_CATEGORIES');
      this.$store.dispatch('shopify/LOAD_FEATURED', 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 100vh;
  padding-top: $nav-height;

  @media($small) {
    padding-top: $nav-height-small;
  }
}

.banner {
  background-color: $color-primary;
  color: $color-white;
  font-weight: bold;
  text-align: center;
  padding: $gp;
}
</style>
