export default {
  track(category, action, label, value) {
    if (window.ga) {
      if (value) {
        window.ga('send', 'event', category, action, label, value);
      } else {
        window.ga('send', 'event', category, action, label);
      }
    }
  },
};
