export default {
  categoryImageByHandle: ({ categories }) => (handleSearch) => {
    const category = categories.find(({ handle }) => {
      return handle === handleSearch;
    });

    if (category && category.image && category.image.url) {
      return category.image.url;
    }

    return null;
  },
};
