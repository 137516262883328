<template>
  <nav :class="{ open: navOpen }">
    <ul>
      <li>
        <router-link to="/products?sort=CREATED_AT|reverse">
          Recently Added
        </router-link>
      </li>

      <li v-for="category in categories" :key="category.id">
        <router-link :to="`/category/${category.handle}`">
          {{ category.title }}
        </router-link>
      </li>

      <li>
        <router-link to="/lebron-exquisite-database">
          LeBron Exquisite Database
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavHeader',

  computed: {
    ...mapState({
      navOpen: ({ global }) => global.navOpen,
      categories: ({ shopify }) => shopify.categories,
    }),
  },
};
</script>

<style lang="scss" scoped>
nav {
  @include transition(transform);

  transform: translateX(-100%);
  background-color: $color-white;
  position: fixed;
  left: 0;
  bottom: 0;
  top: $nav-height;
  z-index: 2;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.open {
    transform: translateX(0);
    box-shadow: $drop-shadow-2;
  }

  @media($small) {
    right: 0;
    top: $nav-height-small;
  }
}

ul {
  padding-bottom: 60px;

  a {
    display: block;
    padding: $gp $gp * 3;

    &.router-link-exact-active {
      color: $color-primary;
    }

    &:hover {
      background-color: $color-gray-lightest;
      text-decoration: none;
    }

    &:active {
      background-color: $color-gray-lighter;
    }
  }
}
</style>
