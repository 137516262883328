<template>
  <div class="offer">
    <h1>Make an Offer</h1>

    <form @submit.prevent="submit">
      <label for="name">Name</label>
      <input name="name" type="text" required v-model="form.name">

      <label for="email">Email</label>
      <input name="email" required type="email" v-model="form.email">

      <label for="card">Card</label>
      <textarea name="card" required v-model="form.card"></textarea>

      <h5>Offer type</h5>

      <div class="input-container">
        <input
          type="radio"
          id="offer-cash"
          value="cash"
          v-model="form.type"
        >
        <label for="offer-cash">Cash offer</label>
      </div>

      <div class="input-container">
        <input
          type="radio"
          id="offer-trade"
          value="trade"
          v-model="form.type"
        >
        <label for="offer-trade">Trade offer</label>
      </div>

      <label for="offer">{{ form.type === 'cash' ? 'Amount' : 'Cards offered' }}</label>
      <textarea name="offer" required v-model="form.offer"></textarea>

      <button :disabled="running" type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Offer',
    meta: [
      {
        property: 'og:title',
        content: 'Offer',
      },
    ],
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        card: '',
        type: 'cash',
        offer: '',
      },
      running: false,
    };
  },

  mounted() {
    if (this.$route.query.card) {
      this.form.card = this.$route.query.card;
    }

    if (this.$route.query.type === 'trade') {
      this.form.type = 'trade';
    }
  },

  methods: {
    submit() {
      this.running = true;

      this.$store.dispatch('contact/OFFER', this.form)
        .then(() => {
          this.$toast({ message: 'Thank you for the offer. We will respond soon.' });
          this.$router.push('/products');
          this.running = false;
        })
        .catch(() => {
          this.$toast({ message: 'There was a problem sending your email', error: true });
          this.running = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.offer {
  @include container;

  h1 {
    text-align: center;
    margin-bottom: $gp * 3;
    margin-top: 0;
  }

  form {
    background-color: $color-white;
    box-shadow: $drop-shadow-1;
    max-width: 500px;
    margin: 0 auto;
    padding: $gp * 2;
  }
}
</style>
