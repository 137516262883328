<template>
  <div class="container">
    <h1>Categories</h1>

    <div class="categories-layout">
      <category
        :category="recentlyAdded"
        url="/products?sort=CREATED_AT|reverse"
      />

      <category
        v-for="category in filteredCategories"
        :category="category"
        :key="category.id"
      />
    </div>

    <div class="view-all" v-if="limit">
      <router-link to="/categories">View All Categories</router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Category from './Category.vue';

const order = [
  'basketball',
  'baseball',
  'jordan-lebron-kobe',
  'football',
  'hockeyother',
  'clothing',
  '90sbasketball',
];

export default {
  components: {
    Category,
  },

  props: {
    limit: Boolean,
  },

  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },

  computed: {
    ...mapState({
      categories: ({ shopify }) => shopify.categories,
    }),

    filteredCategories() {
      const filtered = [];

      order.forEach((sortHandle) => {
        const category = this.categories.find(({ handle }) => handle === sortHandle);

        if (category) {
          filtered.push(category);
        }
      });

      return this.limit ? filtered.splice(0, 7) : filtered;
    },

    recentlyAdded() {
      return {
        title: 'Recently Added',
        image: { url: `${this.baseUrl}img/banners/banner.jpg` },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}

.categories-layout {
  @include card-layout;
}

.view-all {
  font-size: 20px;
  margin: $gp * 4 0 $gp * 2;
  display: flex;
  justify-content: center;
}
</style>
