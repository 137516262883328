function setBodyScrollClass(navOpen) {
  if (navOpen) {
    document.body.classList.add('nav-open');
  } else {
    document.body.classList.remove('nav-open');
  }
}

export default {
  TOGGLE_NAV(state) {
    state.navOpen = !state.navOpen;
    setBodyScrollClass(state.navOpen);
  },

  SET_NAV(state, open) {
    state.navOpen = open;
    setBodyScrollClass(state.navOpen);
  },

  TOGGLE_SEARCH(state) {
    state.searchOpen = !state.searchOpen;
  },

  SET_SEARCH_OPEN(state, open) {
    state.searchOpen = open;
  },
};
