<template>
  <div class="contact">
    <h1>Contact</h1>

    <form @submit.prevent="submit">
      <label for="name">Name</label>
      <input name="name" type="text" required v-model="contact.name">

      <label for="email">Email</label>
      <input name="email" type="email" required v-model="contact.email">

      <label for="message">Message</label>
      <textarea name="message" rows="10" required v-model="contact.message"></textarea>

      <button :disabled="running" type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Contact us',
    meta: [
      {
        property: 'og:title',
        content: 'Contact us',
      },
    ],
  },

  data() {
    return {
      contact: {
        name: '',
        email: '',
        message: '',
      },
      running: false,
    };
  },

  mounted() {
    if (this.$route.query.message) {
      this.contact.message = this.$route.query.message;
    }
  },

  methods: {
    submit() {
      this.running = true;

      this.$store.dispatch('contact/CONTACT', this.contact)
        .then(() => {
          this.$toast({ message: 'Message sent successfully. We will respond soon.' });
          this.$router.push('/');
          this.running = false;
        })
        .catch(() => {
          this.$toast({ message: 'There was a problem sending your email', error: true });
          this.running = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  @include container;

  h1 {
    text-align: center;
    margin-bottom: $gp * 3;
    margin-top: 0;
  }

  form {
    background-color: $color-white;
    box-shadow: $drop-shadow-1;
    max-width: 500px;
    margin: 0 auto;
    padding: $gp * 2;
  }
}
</style>
