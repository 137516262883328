<template>
  <img
    class="logo"
    alt="Buy Nice Cards"
    :src="`${baseUrl}img/logo.png`"
  >
</template>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: var(--logo-size, 56px);
  width: auto;
}
</style>
