<template>
  <select-field v-model="sortKey">
    <option
      v-for="option in sortOptions"
      :key="option.key"
      :value="option.key"
    >
      {{ option.label }}
    </option>
  </select-field>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      filtersOpen: ({ product }) => product.filtersOpen,
      sortOptions: ({ product }) => product.sortOptions,
    }),

    sortKey: {
      get() {
        return this.$store.state.product.sortKey;
      },

      set(value) {
        const { q } = this.$route.query;

        this.$store.commit('product/SET_SORT_KEY', value);
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            sort: value,
            q,
          },
        });
      },
    },
  },
};
</script>
