<template>
  <div class="product">
    <div v-if="loaded">
      <div class="product-container">
        <section class="gallery-container">
          <gallery :images="product.images" />
        </section>

        <section class="info">
          <h1>{{ product.title }}</h1>

          <p class="description">
            This is a {{ product.title }} card. This card is an excellent addition for
            any collector, investor or sports card enthusiast. It comes with our 100%
            Authenticity Guarantee or receive Full Refund.
          </p>

          <p v-html="product.descriptionHtml" />

          <p class="sku"><b>SKU:</b> {{ sku }}</p>

          <product-actions :product="product" />
        </section>
      </div>

      <div class="tags">
        <b>Tags: </b>

        <span v-for="(value, i) in product.tags" :key="value">
          <router-link :to="getTagRoute(value)">{{ value }}</router-link>
          <span v-if="i < product.tags.length - 1">, </span>
        </span>
      </div>

      <similar-products :product="product" />
    </div>

    <spinner v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import Gallery from '../components/Gallery/Gallery.vue';
import ProductActions from '../components/Product/ProductActions.vue';
import SimilarProducts from '../components/Product/SimilarProducts.vue';

export default {
  metaInfo() {
    return {
      title: this.product.title,
      meta: [
        {
          property: 'og:title',
          content: this.product.title,
        },
        {
          property: 'og:type',
          content: 'product',
        },
        {
          property: 'og:image',
          content: this.product.images && this.product.images.length > 0 ? this.product.images[0].url : `${this.baseUrl}img/logo.png`,
        },
      ],
    };
  },

  components: {
    Gallery,
    ProductActions,
    SimilarProducts,
  },

  data() {
    return {
      loaded: false,
    };
  },

  created() {
    this.load();
  },

  beforeDestroy() {
    this.$store.commit('shopify/CLEAR_PRODUCT');
  },

  computed: {
    ...mapState({
      product: ({ shopify }) => shopify.product,
    }),

    handle() {
      return this.$route.params.id;
    },

    createdAt() {
      return moment(this.product.createdAt).format('L');
    },

    variantData() {
      return this.product && this.product.variants.length ? this.product.variants[0] : {};
    },

    sku() {
      return this.variantData.sku;
    },
  },

  watch: {
    handle() {
      this.load();
    },
  },

  methods: {
    load() {
      this.loaded = false;
      this.$store.dispatch('shopify/LOAD_PRODUCT', this.handle)
        .then(() => {
          this.loaded = true;
        });
    },

    getTagRoute(tag) {
      if (tag === 'lebronexquisitedatabase') {
        return {
          name: 'lebronexquisitedatabase',
        };
      }

      return {
        name: 'products',
        query: { q: tag },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  @include container;
}

.product-container {
  display: flex;

  .gallery-container {
    margin-right: $gp * 3;
    flex: 3;
  }

  img {
    max-width: 340px;
    max-height: 340px;
    margin-bottom: $gp * 2;
  }

  @media($small) {
    flex-direction: column;

    .gallery-container {
      margin-right: 0;
      margin-bottom: $gp * 1.5;
    }
  }
}

.tags {
  margin-top: $gp * 4;
}

.info {
  flex: 4;

  h1 {
    font-size: 24px;
  }
}
</style>
