<template>
  <router-link
    class="product"
    :to="`/product/${product.handle}`"
  >
    <div class="img-container">
      <lazy-image
        v-if="displayImg"
        :src="displayImg"
        :alt="product.title"
      />
    </div>

    <div class="title">{{ product.title }}</div>

    <product-actions small :product="product" />
  </router-link>
</template>

<script>
import LazyImage from 'v-lazy-image';

import ProductActions from '@/components/Product/ProductActions.vue';

export default {
  components: {
    LazyImage,
    ProductActions,
  },

  props: {
    product: Object,
  },

  computed: {
    displayImg() {
      return this.product
        && this.product.images.length ? this.product.images[0].url : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  display: block;
  color: $color-black;
  display: flex;
  flex-direction: column;
  padding: $gp * 1.5;
  border-radius: $border-radius;

  &:hover {
    background-color: $color-white;
    box-shadow: $drop-shadow-1;
  }

  @media($small) {
    padding: $gp;
    border-bottom: 1px solid $color-gray-lighter;
  }

  &:hover {
    text-decoration: none;
  }
}

.title {
  flex: 1;
  font-size: 14px;

  @media($small) {
    font-size: 12px;
  }
}

.img-container {
  height: 260px;
  margin-bottom: $gp;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }

  @media($small) {
    height: 200px;
  }
}
</style>
