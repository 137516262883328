<template>
  <div v-if="unavailableType" />

  <div :class="['description', { small }]" v-else-if="!variantData.availableForSale">
    <span class="price">Sold</span>

    <div v-if="!small" class="button-container">
      <button :class="{ small }" type="button" @click.stop.prevent="makeOffer">
        <icon name="message" /> Message
      </button>
    </div>
  </div>

  <div :class="['description', { small }]" v-else-if="saleType">
    <span class="price">{{ $n(variantData.price, 'currency') }}</span>
    <div class="button-container button-row">
      <button :class="{ small }" type="button" @click.stop.prevent="purchase">
        Purchase
      </button>

      <button
        v-if="!small"
        :class="{ small }"
        type="button"
        @click.stop.prevent="makeOffer"
      >
        <icon name="message" /> Message
      </button>
    </div>
  </div>

  <div :class="['description', { small }]" v-else-if="tradeType">
    <span class="price">Trade only</span>

    <div class="button-container">
      <button :class="{ small }" type="button" @click.stop.prevent="makeOffer">
        Make Offer
      </button>
    </div>
  </div>

  <div :class="['description', { small }]" v-else>
    <span />
    <div class="button-container">
      <button :class="{ small }" type="button" @click.stop.prevent="makeOffer">
        Make Offer
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    small: Boolean,
  },

  computed: {
    saleType() {
      return this.product.productType === 'sale' && this.variantData.price > 0;
    },

    tradeType() {
      return this.product.productType === 'trade';
    },

    unavailableType() {
      return this.product.productType === 'unavailable';
    },

    variantData() {
      return this.product
        && this.product.variants
        && this.product.variants.length ? this.product.variants[0] : {};
    },
  },

  methods: {
    purchase() {
      this.$store.dispatch('cart/CHECKOUT', this.product);
    },

    makeOffer() {
      this.$router.push(`/contact?message=${this.product.title}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  color: $color-primary;
  font-weight: $font-weight-bold;
  margin-top: $gp * .5;
  display: flex;
}

.description {
  &.small {
    align-items: center;
    justify-content: space-between;
  }

  &:not(.small) {
    flex-direction: column;
    align-items: flex-start;

    .price {
      font-size: 24px;
      margin-bottom: $gp;
    }

    .button-container {
      @media($small) {
        width: 100%;

        button {
          justify-content: center;
          height: 52px;
          width: 100%;
        }
      }
    }
  }
}

.price {
  margin-right: $gp;
}
</style>
