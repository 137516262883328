<template>
  <div class="container">
    <h1>LeBron Exquisite RPA Database</h1>

    <p class="description">
      The 2003-2004 Upper Deck Exquisite LeBron James RPA is
      possibly the most important modern sports card in existence. The cards
      displayed here are for database purposes. The majority of the cards listed in this section
      are not owned by BuyNiceCards and are <b>not for sale</b>. If you have access to a serial
      number not pictured here please <router-link to="/contact">contact us</router-link>.
    </p>

    <div class="button-group">
      <button :class="['left', { active: active === '99' }]" @click="active = '99'">99</button>
      <button :class="['left', { active: active === '23' }]" @click="active = '23'">23</button>
    </div>

    <div class="description" v-show="loaded">{{ database.length }} of {{ active }} catalogued</div>

    <div class="products" v-if="loaded">
      <product
        v-for="product in database"
        :product="product"
        :key="product.id"
      />
    </div>

    <spinner v-else />
  </div>
</template>
<script>

import Product from '../components/Product/Product.vue';

export default {
  metaInfo: {
    title: 'LeBron Exquisite Database',
    meta: [
      {
        property: 'og:title',
        content: 'LeBron Exquisite Database',
      },
      {
        property: 'og:description',
        content: 'The 2003-2004 Upper Deck Exquisite Collection LeBron James Jersey Patch Auto /99 is possibly the most important modern sports card in existence.',
      },
    ],
  },

  components: {
    Product,
  },

  data() {
    return {
      database: [],
      loaded: false,
      active: '99',
    };
  },

  watch: {
    active() {
      this.load();
    },
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      this.loaded = false;

      this.database = await this.$store.dispatch('shopify/GET_LEBRON_EXQUISITE_DATABASE', { tag: this.active === '23' ? 'lebronexquisiteparalleldatabase' : 'lebronexquisitedatabase' });
      this.database.sort((a, b) => {
        const aTitle = a.title.toUpperCase();
        const bTitle = b.title.toUpperCase();

        if (aTitle < bTitle) {
          return -1;
        }
        if (aTitle > bTitle) {
          return 1;
        }

        return 0;
      });

      this.loaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
.description {
  text-align: center;
}

p.description {
  max-width: 620px;
  margin: 0 auto $gp * 3;
}

.products {
  @include card-layout;
}

.button-group {
  margin: $gp auto;
  text-align: center;
}
</style>
