<template>
  <footer>
    <section class="social">
      <a class="social-container" href="https://www.facebook.com/buynicecards" target="_blank">
        <icon name="facebook" />
      </a>

      <a class="social-container" href="https://www.twitter.com/buynicecards" target="_blank">
        <icon name="twitter" />
      </a>

      <a class="social-container" href="https://www.instagram.com/buynicecards" target="_blank">
        <icon name="instagram" />
      </a>
    </section>

    <section class="about">
      <router-link to="/about" class="link">
        About us
      </router-link>

      <router-link to="/faqs" class="link">
        FAQs
      </router-link>

      <router-link to="/contact" class="link">
        Email us
      </router-link>
    </section>

    <section class="copyright">
      © 2023 BuyNiceCards.com. All Rights Reserved.
    </section>
  </footer>
</template>

<script>
export default {
  name: 'NavFooter',
};
</script>

<style lang="scss" scoped>
footer {
  text-align: center;
  padding: $gp * 4;
  color: $color-gray-mid;
  font-size: 18px;
  background-color: $color-white;
  border-top: 1px solid $color-gray-lighter;

  @media($small) {
    padding: $gp;
    padding-bottom: 60px;
    font-size: 14px;
  }
}

.social {
  --icon-color: #{$color-gray-mid};
  --icon-size: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media($small) {
    --icon-size: 24px;
  }
}

.social-container {
  padding: $gp * .5;
  display: block;

  &:hover {
    --icon-color: #{$color-black};
  }
}

.about {
  margin: $gp * 2 0;

  @media($small) {
    margin: $gp 0;
  }
}

.link {
  padding: 0 $gp;

  &:not(:last-child) {
    border-right: 1px solid $color-gray;
  }
}
</style>
