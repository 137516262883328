<template>
  <div class="about container">
    <h1>About us</h1>

    <h2>Primary Objective</h2>

    <p>
      We are buyers, sellers, traders, consignors, and collectors of rare and
      high end sports cards. Buynicecards.com was created with the idea
      of building a website that is easy to use and looks great with a focus on
      showcasing high end sports cards in a proper manner. The site will allow
      us and others to show as many of our cards that we choose and allow us
      to display them in the manner that works best for us.
    </p>
    <br>

    <h2>Contact us</h2>

    <p>
      Please feel free to reach out to us at any point using the communication
      tool in the bottom right of your screen or the
      <router-link to="/contact">contact us</router-link> feature.
    </p>
    <br>

    <h2>Individual Card Pricing Options</h2>

    <p>
      As collectors we all know that there are some cards that we prize more
      than others. With that in mind we came up with a model that allows us to
      list our cards in several different ways depending on how we might want
      to offer an individual card to the market. There are 3 listing options
      which are explained below:
    </p>

    <h3>Available</h3>
    <p>
      This is card is available for purchase directly through the site.
      You are still able to message us about the card for purchase.
    </p>

    <h3>Trade only</h3>
    <p>
      This card is only available through direct messaging with us using our
      messaging feature in the bottom right. We will respond to your offer as
      quickly as possible.
    </p>

    <h3>Sold</h3>
    <p>This card has been sold and is no longer available for purchase.</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'About us',
    meta: [
      {
        property: 'og:title',
        content: 'About us',
      },
    ],
  },
};
</script>
