import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store/index';

import Home from './views/Home.vue';
import CategoriesView from './views/CategoriesView.vue';
import CategoryView from './views/CategoryView.vue';
import ProductsView from './views/ProductsView.vue';
import ProductView from './views/ProductView.vue';
import About from './views/About.vue';
import Contact from './views/Contact.vue';
import Offer from './views/Offer.vue';
import Faqs from './views/Faqs.vue';
import LeBronExquisiteDatabase from './views/LeBronExquisiteDatabase.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, _, savedPosition) {
    if (savedPosition && to.meta.keepScroll) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/categories',
      name: 'categories',
      component: CategoriesView,
    },
    {
      path: '/category/:id',
      name: 'category',
      component: CategoryView,
    },
    {
      path: '/products',
      name: 'products',
      component: ProductsView,
      meta: {
        keepScroll: true,
      },
    },
    {
      path: '/product/:id',
      name: 'product',
      component: ProductView,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/offer',
      name: 'offer',
      component: Offer,
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: Faqs,
    },
    {
      path: '/lebron-exquisite-database',
      name: 'lebronexquisitedatabase',
      component: LeBronExquisiteDatabase,
      meta: {
        keepScroll: true,
      },
    },
    {
      path: '/latest',
      name: 'latest',
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeResolve((to, from, next) => {
  store.commit('SET_NAV', false);

  if (window.FB) {
    window.FB.CustomerChat.update({
      ref: to.path,
    });
  }

  if (to.path === '/latest') {
    next('/products?sort=CREATED_AT|reverse');
    return;
  }

  if (window.ga) {
    window.ga('set', 'page', to.path);
    window.ga('send', 'pageview');
  }

  next();
});

export default router;
