<template>
  <div class="categories-view">
    <categories-list />
  </div>
</template>

<script>
import CategoriesList from '../components/Category/CategoriesList.vue';

export default {
  metaInfo: {
    title: 'Categories',
  },

  components: {
    CategoriesList,
  },
};
</script>
