<template>
  <div class="similar-products" v-if="showSimilarProducts">
    <h2>Similar Products</h2>

    <div class="products-container">
      <product
        v-for="product in similarProducts"
        :product="product"
        :key="product.id"
      />
    </div>
  </div>
</template>

<script>
import Product from './Product.vue';

export default {
  components: {
    Product,
  },

  props: {
    product: Object,
  },

  created() {
    this.load();
  },

  data() {
    return {
      loaded: false,
      similarProducts: [],
    };
  },

  computed: {
    showSimilarProducts() {
      return this.similarProducts.length && this.product.productType !== 'unavailable';
    },
  },

  methods: {
    load() {
      this.$store.dispatch('shopify/GET_SIMILAR_PRODUCTS', this.product)
        .then((similarProducts) => {
          this.similarProducts = similarProducts;
          this.loaded = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.similar-products {
  margin-top: $gp * 4;
}

.products-container {
  @include card-layout;
}
</style>
