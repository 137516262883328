export default {
  sortOptions: [
    {
      label: 'Date: newest first',
      key: 'CREATED_AT|reverse',
    },
    {
      label: 'Price: high to low',
      key: 'PRICE|reverse',
    },
    {
      label: 'Price: low to high',
      key: 'PRICE',
    },
    {
      label: 'Title: A-Z',
      key: 'TITLE',
    },
    {
      label: 'Title: Z-A',
      key: 'TITLE|reverse',
    },
  ],
  sortKey: 'CREATED_AT|reverse',
  filtersOpen: false,
  filters: {
    availability: 'available',
    grading: 'all',
    tags: [],
  },
};
