<template>
  <svg class="icon">
    <use :xlink:href="'#' + name"/>
  </svg>
</template>

<script>
function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('../../assets/icons/', true, /\.svg$/));

export default {
  name: 'Icon',

  props: {
    name: {
      type: String,
      default: '',
      docdescription: 'The name of the icon',
    },
  },
};
</script>

<style lang="scss" scoped>
  .icon {
    @include transition(all);

    cursor: var(--icon-cursor, pointer);
    height: var(--icon-size, #{$font-size-icons});
    width: var(--icon-size, #{$font-size-icons});
    min-height: var(--icon-size, #{$font-size-icons});
    min-width: var(--icon-size, #{$font-size-icons});
    margin: var(--icon-margin, 0);
    fill: var(--icon-color, currentcolor);
    stroke: var(--icon-color, currentcolor);
    stroke-width: var(--icon-stroke-width, $icon-stroke-width);
  }
</style>

<style lang="scss" rel="stylesheet/scss">
  .is-icon-target,
  .is-icon-target * {
    stroke-width: inherit;
    fill: none;
    vector-effect: non-scaling-stroke;
  }
  .is-icon-dot{
    stroke-width: 0;
    fill: inherit;
  }
</style>
