export default {
  categories: [],
  categoryTitle: 'Loading...',
  products: [],
  pageInfo: { endCursor: null, hasNextPage: false },
  product: {
    title: 'Loading...',
  },
  productCache: {},
  featured: [],
  lastSearch: null,
};
