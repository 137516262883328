<template>
  <div class="products-view">
    <product-filters />

    <div class="products-container" v-if="loaded">
      <div class="filter-sort-container">
        <product-sort />

        <div class="filters-link" @click="openFilters">
          <a>Filters</a>
        </div>
      </div>

      <div v-if="showEmptyResults">
        There are no products with the current filtering.
      </div>

      <div class="products">
        <product
          v-for="product in products"
          :product="product"
          :key="product.id"
        />
      </div>

      <div v-if="pageInfo.hasNextPage" class="more-link">
        <button type="button" @click="loadMore">Lore More</button>
      </div>

      <spinner v-if="loading" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Product from './Product.vue';
import ProductFilters from './ProductFilters.vue';
import ProductSort from './ProductSort.vue';

export default {
  components: {
    Product,
    ProductFilters,
    ProductSort,
  },

  data() {
    return {
      limit: 40,
      loaded: false,
      loading: false,
    };
  },

  watch: {
    handle() {
      this.load();
    },

    queryParam() {
      this.load();
    },

    sortParam() {
      this.load();
    },

    availabilityFilter() {
      this.load();
    },

    gradingFilter() {
      this.load();
    },

    tagsFilter: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  created() {
    this.load();
  },

  computed: {
    ...mapState({
      sortKey: ({ product }) => product.sortKey,
      availabilityFilter: ({ product }) => product.filters.availability,
      gradingFilter: ({ product }) => product.filters.grading,
      tagsFilter: ({ product }) => product.filters.tags,
      pageInfo: ({ shopify }) => shopify.pageInfo,
      products: ({ shopify }) => shopify.products,
    }),

    handle() {
      return this.$route.params.id;
    },

    showEmptyResults() {
      return this.loaded && this.products.length === 0 && !this.loading;
    },

    queryParam() {
      return this.$route.query.q;
    },

    sortParam() {
      return this.$route.query.sort;
    },
  },

  methods: {
    loadMore() {
      this.load(false);
    },

    load(reset = true) {
      if (reset) {
        this.$store.commit('shopify/CLEAR_PRODUCTS');
        this.loaded = false;
      }

      this.loading = true;

      if (this.sortParam) {
        this.$store.commit('product/SET_SORT_KEY', this.sortParam);
      }

      const [sort, reverse] = this.sortKey.split('|');
      const tags = [];
      const search = this.queryParam || '';

      let handle = null;

      if (this.$route.name === 'category') {
        handle = this.$route.params.id;
      }

      if (this.gradingFilter !== 'all') {
        tags.push(this.gradingFilter);
      }

      Array.prototype.push.apply(tags, this.tagsFilter);

      if (reset) {
        window.scrollTo(0, 0);
      }

      this.$store.dispatch('shopify/LOAD_PRODUCTS', {
        search,
        sort,
        reverse,
        limit: this.limit,
        tags,
        availability: this.availabilityFilter,
        handle,
        endCursor: this.pageInfo.endCursor,
      })
        .then(() => {
          this.loaded = true;
          this.loading = false;
        });
    },

    openFilters() {
      this.$store.commit('product/SET_FILTERS_OPEN', true);
    },

    closeFilters() {
      this.$store.commit('product/SET_FILTERS_OPEN', false);
    },

    clear() {
      this.$router.push('/products');
    },
  },
};
</script>

<style lang="scss" scoped>
.products-view {
  display: flex;

  @media($small) {
    flex-direction: column;
  }
}

.products-container {
  flex: 1;
}

.filter-sort-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: $gp;

  @include small-push-edge;

  @media($small) {
    border-bottom: 1px solid $color-gray-lighter;
    background-color: $color-white;
    padding: $gp * .75;
    position: sticky;
    top: $nav-height-small;
    z-index: 1;
  }

  @media($medium) {
    flex-direction: row-reverse;
    width: 300px;
    margin-left: auto;

    .filters-link {
      display: none;
    }
  }
}

.more-link {
  text-align: center;
  margin-top: $gp * 2;
}

.filters-link {
  margin-left: $gp;
}

.products {
  @include card-layout(0);
}

.sort-container {
  display: flex;
  justify-content: flex-end;
}
</style>
