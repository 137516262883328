import axios from 'axios';

export default {
  CONTACT(context, payload) {
    return contact(context, payload);
  },

  OFFER(context, payload) {
    return offer(context, payload);
  },
};

const contact = (_, contactForm) => {
  return axios.post(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/contact`, contactForm);
};

const offer = (_, offerForm) => {
  return axios.post(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/offer`, offerForm);
};
