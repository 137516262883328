import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import cart from './cart';
import contact from './contact';
import global from './global';
import product from './product';
import shopify from './shopify';

Vue.use(Vuex);

export const modules = {
  cart,
  contact,
  global,
  product,
  shopify,
};

const vuexPersistPathsWhiteList = [
  'cart',
  'product.sortKey',
];

export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      key: 'buynicecards',
      paths: vuexPersistPathsWhiteList,
    }),
  ],
});
