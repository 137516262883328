<template>
  <div class="animated-background" v-show="loading">
    <div
      class="row"
      v-for="({ height, type, boxes }, i) in computedRows"
      :key="i"
      :style="{ height }"
      :class="[type]"
    >
      <div
        class="box"
        v-for="(box, i) in boxes"
        :key="i"
        :class="[box.type]"
        :style="{ flex: `${box} 0 0` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Placeholder',

  props: {
    rows: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    computedRows() {
      return this.rows.reduce((newRows, row, i, rows) => {
        const { height } = row;

        newRows.push({
          boxes: row.boxes.reduce((newBoxes, box, j, boxes) => {
            newBoxes.push(box);

            if (j !== boxes.length - 1) {
              newBoxes.push({ type: 'divider' });
            }

            return newBoxes;
          }, []),
          height,
        });

        if (i !== rows.length - 1) {
          newRows.push({ type: 'divider' });
        }

        return newRows;
      }, []);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.box {
  overflow: hidden;
  height: 100%;

  &.divider {
    width: $gp;

    @media($medium) {
      background-color: $color-gray-lightest;
    }
  }
}

.row {
  display: flex;
  height: $gp;
  flex: 0 1 auto;

  &.divider {
    background-color: $color-gray-lightest;
  }
}

@keyframes placeholderAnimation {
  0% {
    background-position: -450px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

@keyframes placeholderAnimationRtl {
  0% {
    background-position: 500px 0;
  }

  100% {
    background-position: -450px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderAnimation;
  animation-timing-function: linear;
  background: $placeholder-background;
  background-size: 950px 100px;
  border-radius: var(--placeholder-border-radius, 0);
  width: var(--placeholder-width, 100%);
  position: relative;
}
</style>
