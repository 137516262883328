<template>
  <form
    :class="['global-search', { open }]"
    @submit.prevent="submit"
  >
    <input
      ref="input"
      type="search"
      v-model="query"
      placeholder="Search"
    >
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GlobalSearch',

  data() {
    return {
      query: '',
    };
  },

  computed: {
    ...mapState({
      open: ({ global }) => global.searchOpen,
    }),
  },

  watch: {
    open(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.input.focus();
          this.$refs.input.setSelectionRange(0, this.$refs.input.value.length);
        });
      }
    },
  },

  methods: {
    submit() {
      if (this.query) {
        this.$track('Nav', 'search', 'string', this.query);
        this.$store.commit('product/RESET_FILTERS');
        this.$router.push(`/products?q=${this.query}`);
        this.$store.commit('SET_SEARCH_OPEN', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.global-search {
  @include transition(transform);

  background-color: $color-white;
  padding: $gp;
  position: fixed;
  z-index: 10;
  right: 0;
  top: $nav-height;
  display: flex;
  align-items: center;
  transform: translateX(100%);

  @media($small) {
    top: $nav-height-small;
    width: 100%;
    padding: $gp * .5;
  }

  &.open {
    transform: translateX(0);
    box-shadow: $drop-shadow-2;
  }
}

input[type="search"] {
  min-width: 300px;
  margin-bottom: 0;
}
</style>
