<template>
  <div class="home">
    <section class="main">
      <div class="container">
        <div class="search-section">
          <form @submit.prevent="search" class="horizontal-form">
            <input
              placeholder="Player, set or sport"
              type="search"
              v-model="textSearch"
            >
            <button class="large" type="submit">Search</button>
          </form>
        </div>

        <h1>High end sports cards you can't find anywhere else</h1>
      </div>

      <div class="featured">
        <router-link
          class="featured-card"
          :to="`/product/${product.handle}`"
          v-for="product in slicedFeatured"
          :key="product.handle"
        >
          <img :src="product.images[0].url" :alt="product.title">
        </router-link>
      </div>
    </section>

    <div class="home-section">
      <categories-list limit />
    </div>

    <!-- <div class="reviews-banner">
      <div class="elfsight-app-6be6825f-3ce6-4008-8c3d-2fd0973e314c"></div>
    </div> -->

    <div class="subscribe-container">
      <div class="container">
        <div class="subscribe-section">
          <div>
            <h3>Join our mailing list</h3>
            <subscribe /><br>
            <i>Privacy policy. We hate spam and will protect your email</i>
          </div>
        </div>
      </div>
    </div>

    <section class="banner-section">
      <router-link to="/products?sort=CREATED_AT|reverse" class="banner">
        <lazy-image
          src="../img/banners/banner.jpg"
          alt="Buy Nice Cards Michael Jordan Banner"
        />
      </router-link>

      <div class="text">
        <div class="text-wrapper">
          <h3>Find what you've been looking for</h3>

          <p>
            Buying, selling and trading high end sports cards from people who know high end.
          </p>

          <p>
            At BuyNiceCards we have the largest high end sports card collection
            available for sale in the world.
          </p>

          <p>
            Looking to invest in sports cards? We got you covered. Find exactly what
            you need to start your portfolio.
          </p>
        </div>
      </div>
    </section>

    <section class="banner-section">
      <div class="text">
        <div class="text-wrapper">
          <h3>We're here to help</h3>

          <p>
            Tired of dealing with annoying sellers on eBay? BuyNiceCards is here
            to help you find your dream card. We want you to
            feel comfortable spending your hard earned money. Trust the best.
          </p>

          <p>
            We're always just one click away and we're always ready to help you
            find what you want. Click the chat bubble in the bottom right at any
            time. We think there's a deal for you here.
          </p>
        </div>
      </div>

      <div class="banner support-banner">
        <svg width="100" height="92" viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.516 9.953C24.516 4.453 19.04 0 12.258 0 5.476 0 0 4.452 0 9.953c0 3.318
            1.986 6.24 5.05 8.053-.34 2.552-1.815 4.055-1.844 4.084-.14.14-.17.368-.113.567a.524.524
            0 0 0 .482.312c2.95 0 5.335-1.93 6.612-3.206.652.086 1.362.142 2.07.142 6.783 0
            12.26-4.452 12.26-9.953z"
            fill="#FFF"
            fill-rule="evenodd"
          >
          </path>
        </svg>
      </div>
    </section>

    <section class="banner-section">
      <router-link
        to="/lebron-exquisite-database"
        class="banner"
      >
        <lazy-image
          src="../img/banners/exquisite.jpg"
          alt="Exquisite LeBron James"
        />
      </router-link>

      <div class="text">
        <div class="text-wrapper">
          <h3>LeBron Exquisite RPA Database</h3>

          <p>
            Keep up with the most important modern sports card in existence. We have tabs on a large
            majority of the copies out in the wild. Take a look at what's out there. Learn about
            the history and importance of the card and see it's beauty.
          </p>

          <p>
            <router-link to="/lebron-exquisite-database">View database</router-link>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LazyImage from 'v-lazy-image';

import CategoriesList from '../components/Category/CategoriesList.vue';
import Subscribe from '../components/Account/Subscribe.vue';

export default {
  metaInfo: {
    title: 'Home',
  },

  components: {
    CategoriesList,
    LazyImage,
    Subscribe,
  },

  data() {
    return {
      textSearch: '',
      slicedFeatured: [],
    };
  },

  created() {
    window.addEventListener('resize', this.setFeaturedArray);
    this.setFeaturedArray();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setFeaturedArray);
  },

  watch: {
    featured: {
      handler() {
        this.setFeaturedArray();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      featured: ({ shopify }) => shopify.featured,
    }),
  },

  methods: {
    search() {
      this.$track('Home', 'search', 'string', this.textSearch);
      this.$store.commit('product/RESET_FILTERS');
      this.$router.push(`/products?q=${this.textSearch}`);
    },

    setFeaturedArray() {
      let slicedFeatured = this.featured.slice(0, 6);
      const documentWidth = document.body.clientWidth;

      slicedFeatured = slicedFeatured.slice(0, Math.floor(documentWidth / 250));

      this.slicedFeatured = slicedFeatured;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
}

.home-section {
  min-height: 500px;
  border-top: 1px solid $color-gray-lighter;
  border-bottom: 1px solid $color-gray-lighter;
}

.reviews-banner {
  padding: 100px 0;
  background-color: white;
  border-bottom: 1px solid $color-gray-lighter;
}

.banner-section {
  display: flex;
  align-items: center;

  h3 {
    font-weight: $font-weight-bold;
    font-size: 28px;
  }

  .banner {
    min-height: 270px;

    @media($small) {
      min-height: 230px;
    }
  }

  .text {
    padding: $gp * 2;
  }

  .text-wrapper {
    max-width: 600px;
  }

  @media($medium) {
    .text,
    .banner {
      flex: 1;
      width: 50%;
      max-width: 50%;
    }
  }

  @media($small) {
    &:nth-of-type(odd) {
      flex-direction: column-reverse;
    }

    flex-direction: column;
  }
}

.main {
  min-height: 400px;

  h1 {
    margin-bottom: 0;
    text-align: center;
  }
}

.search-section {
  display: flex;
  flex-direction: column;
  margin-bottom: $gp;

  @media($medium) {
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-bottom: $gp * .5;
  }
}

.subscribe-container {
  background-color: $color-white;
}

.subscribe-container {
  border-bottom: 1px solid $color-gray-lighter;
}

.subscribe-section {
  display: flex;
  justify-content: center;
  padding: $gp * 3 0;

  h3 {
    font-weight: $font-weight-bold;
    font-size: 28px;
    margin-bottom: $gp;
  }
}

.container {
  @include container;
}

.featured {
  display: flex;
  min-height: 320px;
  height: 320px;
  margin-bottom: $gp;
  justify-content: center;
}

.featured-card {
  display: inline-block;
  min-width: 200px;
  max-width: 200px;

  &:not(:last-child) {
    margin-right: $gp * 1.5;
  }
}

.support-banner {
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
</style>
