<template>
  <router-link
    class="category"
    :to="computedUrl"
    :title="category.title"
  >
    <img :src="imageUrl" :alt="category.title">
    <h3>{{ category.title }}</h3>
  </router-link>
</template>

<script>
export default {
  props: {
    category: Object,
    url: String,
  },

  computed: {
    computedUrl() {
      return this.url || `/category/${this.category.handle}`;
    },

    imageUrl() {
      return this.category.image ? this.category.image.url : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: block;
  height: 280px;
  box-shadow: $drop-shadow-2;
  border-radius: $border-radius;
  position: relative;
  text-align: center;

  @media($medium-down) {
    height: 240px;
    max-width: 320px;
    width: 100%;

    img {
      height: 240px;
    }
  }

  img {
    object-fit: cover;
    object-position: 50% 15%;
    width: 100%;
    height: 280px;
  }

  &:hover {
    text-decoration: none;
  }

  h3 {
    display: inline-block;
    padding: $gp $gp * 2;
    background-color: $color-black;
    color: $color-white;
    margin-bottom: 0;
    font-weight: $font-weight-bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
