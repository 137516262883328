export default {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },

  CLEAR_CATEGORY(state) {
    state.categoryTitle = 'Loading...';
  },

  SET_PRODUCTS(state, { products, collectionByHandle, pageInfo }) {
    state.products = products;
    state.pageInfo = pageInfo;

    if (collectionByHandle) {
      state.categoryTitle = collectionByHandle.title;
    }
  },

  ADD_MORE_PRODUCTS(state, { products, pageInfo }) {
    state.products.push(...products);
    state.pageInfo = pageInfo;
  },

  CLEAR_PRODUCTS(state) {
    state.products = [];
    state.pageInfo = { endCursor: null, hasNextPage: false };
  },

  SET_PRODUCT(state, product) {
    state.product = product;

    state.productCache[product.handle] = product;
  },

  CLEAR_PRODUCT(state) {
    state.product = {
      title: 'Loading...',
    };
  },

  SET_FEATURED(state, products) {
    state.featured = products;
  },

  SAVE_LAST_SEARCH(state, search) {
    state.lastSearch = search;
  },
};
