import axios from 'axios';

const axiosConfig = {
  method: 'post',
  maxBodyLength: Infinity,
  url: process.env.VUE_APP_SHOPIFY_GRAPH_QL_URL,
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.VUE_APP_SHOPIFY_TOKEN,
    'Content-Type': 'application/json',
  },
};

export default {
  CHECKOUT(context, product) {
    return createCheckout(context, [product]);
  },
};

const createCheckout = ({ commit }, products) => {
  return new Promise((_, reject) => {
    const lineItemsForCheckout = products
      .filter(({ productType, variants }) => {
        const saleProductType = productType === 'sale';
        const variantData = variants && variants.length ? variants[0] : {};
        const availableForSale = variantData.availableForSale && variantData.price > 0;

        return saleProductType && availableForSale;
      })
      .map((product) => {
        return {
          variantId: product.variants[0].id,
          quantity: 1,
        };
      });

    const request = JSON.stringify({
      query: `mutation($input: CheckoutCreateInput!) {
        checkoutCreate(input: $input) {
          checkout {
            id
            webUrl
          }
          checkoutUserErrors {
            field
            message
          }
        }
      }`,
      variables: { input: { lineItems: lineItemsForCheckout } },
    });

    axiosConfig.data = request;

    axios.request(axiosConfig)
      .then(({ data }) => {
        window.location.href = data.data.checkoutCreate.checkout.webUrl.replace('buy-nice-cards.myshopify.com', 'shop.buynicecards.com');
      })
      .catch(reject);
  });
};
