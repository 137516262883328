<template lang="html">
  <div class="gallery">
    <div class="display">
      <figure
        v-show="loaded"
        class="zoom"
        :style="{ backgroundImage: `url(${selected.url})` }"
        @mousemove="zoom"
      >
        <img
          ref="img"
          :src="selected.url"
          :alt="selected.altText"
          @load="loaded = true"
          @click.prevent.stop="handleImageClick"
        >
      </figure>

      <placeholder class="figure-placeholder" :rows="figurePlaceholder" :loading="!loaded" />

      <div class="prev arrow" @click="previous" v-show="hasPrevious">
        <icon name="chevron-left" />
      </div>

      <div class="next arrow" @click="next" v-show="hasNext">
        <icon name="chevron-right" />
      </div>
    </div>

    <div class="thumbs" v-show="loaded">
      <div
        :class="['thumb', { active: selected.url === image.url }]"
        v-for="(image, index) in images"
        :key="image.id"
        @click="selectImage(index)"
      >
        <img :src="image.url" :alt="image.altText">
      </div>
    </div>

    <div class="thumbs-placeholder-wrapper">
      <placeholder class="thumbs-placeholder" :rows="thumbsPlaceholder" :loading="!loaded" />
    </div>

    <div :class="['featured-image', { active: featuredVisible }]" @click="closeFeatured">
      <img :src="selected.url">
    </div>
  </div>
</template>

<script>
import dom from '@/mixins/dom';

export default {
  props: {
    images: Array,
  },

  mixins: [dom],

  data() {
    return {
      selectedIndex: 0,
      lens: null,
      cx: null,
      cy: null,
      loaded: false,
      figurePlaceholder: [{ height: '31.25rem', boxes: [1] }],
      thumbsPlaceholder: [{ height: '5.625rem', boxes: [1, 1, 1, 1] }],
      featuredVisible: false,
    };
  },

  watch: {
    images: {
      handler() {
        this.selectImage(0);
      },
      deep: true,
    },
  },

  computed: {
    selected() {
      return this.images[this.selectedIndex];
    },

    hasPrevious() {
      return this.images.length > 0 && this.selected !== this.images[0];
    },

    hasNext() {
      return this.images.length > 1 && this.selected !== this.images[this.images.length - 1];
    },
  },

  methods: {
    selectImage(index) {
      this.selectedIndex = index;
    },

    zoom(e) {
      if (!this.dom_isTouchDevice()) {
        const zoomer = e.currentTarget;

        const offsetX = e.offsetX ? e.offsetX : e.touches[0].pageX;
        const offsetY = e.offsetY ? e.offsetY : e.touches[0].pageX;

        const x = (offsetX / zoomer.offsetWidth) * 100;
        const y = (offsetY / zoomer.offsetHeight) * 100;

        zoomer.style.backgroundPosition = `${x}% ${y}%`;
      }
    },

    previous() {
      this.selectedIndex -= 1;
    },

    next() {
      this.selectedIndex += 1;
    },

    handleImageClick() {
      this.featuredVisible = true;
    },

    closeFeatured() {
      this.featuredVisible = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.gallery {
  @include small-push-edge;
}

.display {
  height: 500px;
  margin-bottom: $gp;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $color-gray;
  position: relative;

  @media($small) {
    height: 300px;
  }
}

.figure-placeholder,
.thumbs-placeholder {
  width: 315px;

  @media($small) {
    display: none;
  }
}

.thumbs-placeholder-wrapper {
  display: flex;
  justify-content: center;

  @media($small) {
    display: none;
  }
}

.zoom {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  img {
    @include transition(opacity);
    display: block;
    max-height: 500px;
    max-width: 500px;

    &:hover {
      opacity: 0;
    }

    @media($small) {
      max-height: 300px;
      max-width: 300px;
    }
  }
}

.arrow {
  position: absolute;
  height: 40px;
  top:  calc(50% - 20px);
  width: 40px;
  background-color: $color-black;
  opacity: 0.6;
  --icon-color: #{$color-white};
  --icon-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.thumbs {
  display: flex;
  justify-content: center;
  padding: 0 $gp;

  @media($small) {
    justify-content: flex-start;
    overflow-y: auto;
  }
}

.thumb {
  height: 90px;
  max-width: 90px;
  padding: $gp * .25;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  cursor: pointer;
  border: 2px solid $color-gray-light;

  img {
    max-height: 100%;
  }

  &.active {
    border-color: $color-primary;
  }

  &:not(:last-child) {
    margin-right: $gp * .25;
  }
}

.featured-image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: $color-white;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding: $gp;

  &.active {
    display: flex;
  }
}
</style>
