<template>
  <transition-group name="list" tag="div" id="toast-group" />
</template>

<script>
export default {
  name: 'ToastGroup',
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
#toast-group {
  position: fixed;
  bottom: $gp;
  left: $gp;
  z-index: 200;

  display: flex;
  flex-direction: column-reverse;

  @media($small) {
    left: $gp;
  }
}
</style>
