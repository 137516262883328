<template>
  <div class="container">
    <div class="breadcrumbs">
      <router-link to="/categories">Categories</router-link>
      <span class="caret">></span>
      <span>{{ categoryTitle }}</span>
    </div>

    <product-list />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ProductList from '../components/Product/ProductList.vue';

export default {
  metaInfo() {
    return {
      title: `${this.categoryTitle} Category`,
      meta: [
        {
          property: 'og:title',
          content: `${this.categoryTitle} Category`,
        },
        {
          property: 'og:type',
          content: 'product',
        },
        {
          property: 'og:image',
          content: this.categoryImage ? this.categoryImage : `${this.baseUrl}img/logo.png`,
        },
      ],
    };
  },

  components: {
    ProductList,
  },

  beforeDestroy() {
    this.$store.commit('shopify/CLEAR_CATEGORY');
  },

  computed: {
    ...mapState({
      categoryTitle: ({ shopify }) => shopify.categoryTitle,
    }),

    handle() {
      return this.$route.params.id;
    },

    categoryImage() {
      return this.$store.getters['shopify/categoryImageByHandle'](this.handle);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @media($small) {
    padding-top: 0;
  }
}

.breadcrumbs {
  margin-bottom: $gp;

  .caret {
    padding: 0 $gp * .5;
  }

  @media($small) {
    display: none;
  }
}
</style>
