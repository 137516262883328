import Vue from 'vue';
import 'core-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import VueI18n from 'vue-i18n';
import { sync } from 'vuex-router-sync';
import Meta from 'vue-meta';

import App from './App.vue';
import router from './router';
import store from './store';

import analytics from './mixins/analytics';

import './styles/main.scss';
import './components';

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(Meta);
sync(store, router);

Vue.prototype.$track = analytics.track;

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
};

Vue.prototype.$firebase = firebase.initializeApp(config);

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
};

const i18n = new VueI18n({
  numberFormats,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
