<template>
  <div class="container">
    <div class="search-results" v-if="queryParam">
      Results for <b>{{ queryParam }}</b>
      <a class="clear" @click="clear">Clear</a>
    </div>

    <product-list />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductList from '../components/Product/ProductList.vue';

export default {
  components: {
    ProductList,
  },

  computed: {
    ...mapGetters({
      products: 'shopify/filteredProducts',
    }),

    queryParam() {
      return this.$route.query.q;
    },
  },

  methods: {
    clear() {
      this.$router.push('/products');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @media($small) {
    padding-top: 0;
  }
}

.search-results {
  @include small-push-edge;

  @media($small) {
    padding: $gp;
    background-color: $color-white;
  }

  @media($medium) {
    margin-bottom: $gp;
  }
}

.clear {
  margin-left: $gp;
}
</style>
