<template>
  <div :class="['filters', { open: filtersOpen }]">
    <icon class="filters-close" name="x" @click.native="closeFilters" />

    <section class="filter">
      <h5>Availability</h5>

      <div class="input-container">
        <input
          type="radio"
          id="availability-available"
          value="available"
          v-model="availabilityFilter"
        >
        <label for="availability-available">Available</label>
      </div>

      <div class="input-container">
        <input
          type="radio"
          id="availability-sold"
          value="sold"
          v-model="availabilityFilter"
        >
        <label for="availability-sold">Available & Sold</label>
      </div>
    </section>

    <section class="filter">
      <h5>Grading</h5>

      <div class="input-container">
        <input
          type="radio"
          id="grading-all"
          value="all"
          v-model="gradingFilter"
        >
        <label for="grading-all">All</label>
      </div>

      <div class="input-container">
        <input
          type="radio"
          id="grading-available"
          value="graded"
          v-model="gradingFilter"
        >
        <label for="grading-available">Graded</label>
      </div>

      <div class="input-container">
        <input
          type="radio"
          id="grading-unavailable"
          value="ungraded"
          v-model="gradingFilter"
        >
        <label for="grading-unavailable">Ungraded</label>
      </div>
    </section>

    <section class="filter">
      <h5>Tags</h5>

      <div class="input-container">
        <input type="checkbox" id="tags-autos" value="autos" v-model="tagsFilter">
        <label for="tags-autos">Autos</label>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      filtersOpen: ({ product }) => product.filtersOpen,
    }),

    availabilityFilter: {
      get() {
        return this.$store.state.product.filters.availability;
      },

      set(value) {
        this.$store.commit('product/SET_AVAILABILITY_FILTER', value);
      },
    },

    gradingFilter: {
      get() {
        return this.$store.state.product.filters.grading;
      },

      set(value) {
        this.$store.commit('product/SET_GRADING_FILTER', value);
      },
    },

    tagsFilter: {
      get() {
        return this.$store.state.product.filters.tags;
      },

      set(value) {
        this.$store.commit('product/SET_TAGS_FILTER', value);
      },
    },
  },

  methods: {
    closeFilters() {
      this.$store.commit('product/SET_FILTERS_OPEN', false);
    },
  },
};
</script>

<style lang="scss" scoped>
$filter-width: 220px;

.filters {
  background-color: $color-gray-background;
  border-radius: $border-radius;
  padding: $gp * 1.5;
  width: $filter-width;
  min-width: $filter-width;

  @media($medium) {
    margin-right: $gp * 1.5;
    position: sticky;
    overflow-x: hidden;
    z-index: 1;
    top: calc(#{$nav-height + $gp * 1.5});
    height: 75vh;
    z-index: 1;
  }

  @media($small) {
    @include transition(transform);

    transform: translateX(100%);
    padding-top: $gp * 1.5;
    position: fixed;
    top: $nav-height-small;
    right: 0;
    bottom: 0;
    z-index: 2;

    &.open {
      transform: translateX(0);
      border-left: 1px solid $color-gray-lighter;
    }
  }
}

.filters-close {
  --icon-size: 30px;

  position: absolute;
  top: $gp;
  right: $gp;

  @media($medium) {
    display: none;
  }
}
</style>
