import Vue from 'vue';

import Icon from './Icon/Icon.vue';
import Logo from './Logo/Logo.vue';
import Spinner from './Spinner/Spinner.vue';
import SelectField from './SelectField/SelectField.vue';
import Placeholder from './Placeholder/Placeholder.vue';

import Toast from './Toast/Toast';

Vue.component('Icon', Icon);
Vue.component('Logo', Logo);
Vue.component('Spinner', Spinner);
Vue.component('SelectField', SelectField);
Vue.component('Placeholder', Placeholder);

Vue.prototype.$toast = Toast;
