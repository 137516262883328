<template>
  <div class="faq container">
    <h1>Frequently Asked Questions</h1>

    <div class="question">
      <h4>Is BuyNiceCards.com interested in buying cards and collections?</h4>
      <p>Absolutely, that is why we chose our name!</p>
    </div>

    <div class="question">
      <h4>Can I sell my cards on BuyNiceCards.com?</h4>
      <p>
        Yes you can.  We have different options depending on your goals and we
        consign at competitive rates.  In effort to gain maximum exposure to
        your cards we can showcase it for you over several selling platforms
        to give it the best chance to sell. If you would like to find our more
        about this option please us the communication tool in the bottom right or
        <router-link to="/contact">contact us</router-link>.
      </p>
    </div>

    <div class="question">
      <h4>How do I pay for a card that I would like to purchase?</h4>
      <p>
        Our shopping cart is setup with the ability to purchase with credit
        card and paypal so if you find a card you would to buy you can add it
        to the shopping the cart, checkout and pay with either. If You make an
        offer on a card and it is accepted we can send you a paypal invoice.
      </p>
    </div>

    <div class="question">
      <h4>What is the scratch free guarantee?</h4>
      <p>
        We offer a scratch free guarantee on graded card holders. If you receive
        a graded card and the holder has been scratched or chipped please contact
        <router-link to="/contact">notify us</router-link> of the damage upon
        receipt of the card and can facilitate a return for a full refund.
      </p>
    </div>

    <div class="question">
      <h4>Can I make an offer?</h4>
      <p>
        We are always open to hearing resonable offers, you can make an offer
        on a card by clicking "Message". We will respond to all offers.
      </p>
    </div>

    <div class="question">
      <h4>How do I make a trade?</h4>
      <p>
        We love trading! Cards being offered for trade will have a message
        button which lets you quickly communicate directly with us so we can
        discuss a trade.
      </p>
    </div>

    <div class="question">
      <h4>
        I received my card and I am not happy with it or the case is scrathed
        or damaged, what do I do?
      </h4>
      <p>
        If there is any issue with your card upon receipt please contact us right
        away to notify us of the situation. We will cover the shipping cost to
        return the card and issue a full refund upon receipt of the card in proper
        order. We offer a scratch free guarantee so if you receive a graded card
        with damage to the case you can return it for a full refund within 14 days
        from the date of purchase.
      </p>
    </div>

    <div class="question">
      <h4>
        Do you offer any type of finanacing or payment options that would
        allow to purchase a card and pay for it over an period of time?
      </h4>
      <p>
        Yes we do, please <router-link to="/contact">contact us</router-link>
        to inquire about this option.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Faqs',
    meta: [
      {
        property: 'og:title',
        content: 'Faqs',
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.question {
  margin-bottom: $gp * 2;

  h4 {
    font-weight: $font-weight-bold;
    margin-bottom: $gp * .5;
  }
}
</style>
