<template>
  <header>
    <div class="menu-container" @click="toggleNav">
      <icon v-show="!navOpen" name="hamburger" />
      <icon v-show="navOpen" name="x" />
    </div>

    <router-link to="/">
      <logo />
    </router-link>

    <div class="right">
      <div class="search-container" @click="toggleSearch">
        <icon v-show="!searchOpen" name="search" />
        <icon v-show="searchOpen" name="x" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavHeader',

  computed: {
    ...mapState({
      navOpen: ({ global }) => global.navOpen,
      searchOpen: ({ global }) => global.searchOpen,
    }),
  },

  methods: {
    toggleNav() {
      this.$store.commit('TOGGLE_NAV');
    },

    toggleSearch() {
      this.$store.commit('TOGGLE_SEARCH');
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  height: $nav-height;
  border-bottom: 1px solid $color-gray-lighter;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;

  @media($small) {
    --logo-size: 44px;
    height: $nav-height-small;
  }
}

.right {
  display: flex;
  align-items: center;
}

.menu-container,
.search-container {
  padding: 0 $gp * 2;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color-black;
  --icon-color: #{$color-black};

  @media($small) {
    padding: 0 $gp;
  }
}
</style>
