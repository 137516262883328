<template>
  <transition name="fade" @after-leave="destroy">
    <div class="toast" @click="close" v-show="active">
      <icon v-if="!error" name="check-circle" class="success" />
      <icon v-if="error" name="x" class="error" />

      <div class="toast-message">
        <div>{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/Icon/Icon.vue';

const DEFAULT_TIMEOUT = 5000;

export default {
  name: 'Toast',

  components: {
    Icon,
  },

  data() {
    return {
      active: false,
      message: '',
      timer: null,
      error: false,
    };
  },

  mounted() {
    this.timer = setTimeout(() => {
      this.close();
    }, DEFAULT_TIMEOUT);
  },

  methods: {
    close() {
      this.active = false;
    },

    destroy() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  @include prevent-select;
  display: flex;
  justify-content: top;
  box-shadow: $drop-shadow-1;
  background-color: $color-white;
  border-radius: $border-radius;
  padding: $gp;
  margin-top: $gp;
  z-index: 200;
}

.toast-message {
  font-size: 18px;
  font-weight: $font-weight-semibold;
  align-self: center;
}

.success,
.error {
  align-self: top;
  margin-right: $gp;
}

.success {
  --icon-color: #{$color-success};
}

.error {
   --icon-color: #{$color-error};
}
</style>
